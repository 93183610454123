@import url('https://fonts.googleapis.com/css2?family=Nova+Square&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@700&display=swap');

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.content-background {
  background-color: var(--bg-primary);
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg1-layer1 {
  background-image: url('./wave.svg');
  background-repeat: repeat-x;
  background-position-y: center;
  background-position-x: 0px;
  animation-name: phase1;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background-color: var(--bg-primary);
}

.bg1-layer2 {
  background-image: url('./wave.svg');
  background-repeat: repeat-x;
  background-position-y: center;
  background-position-x: 200px;
  animation-name: phase2;
  animation-duration: 19s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: scaleY(0.7) translateY(10vh);

  width: 100%
}

@keyframes phase1 {
  0% { background-position-x: 0px; }
  100% { background-position-x: 2700px; }
}

@keyframes phase2 {
  0% { 
    background-position-x: 0px;
    transform: scaleY(0.75) translateY(5vh);
  }
  50% { 
    background-position-x: 1350px; 
    transform: scaleY(0.75) translateY(-5vh);
  }
  100% { 
    background-position-x: 2700px;
    transform: scaleY(0.75) translateY(5vh);
   }
}

.profile-image {
  border-radius: 50%;
  height: 40vh;
  border: 10px solid #CCCACD;
}

.profile h1 {
  color: #CCCACD;
  text-shadow: 4px 4px 0px #7A7C8B;
}

.profile h2 {
  color: #7A7C8B;
}

.profile * {
  margin: 0px 0px;
}

.projects {
  color: white;
  padding: 0px 10vw;
}

.projects * {
  margin: 0px;
}

.projects :nth-child(odd) {
  flex-direction: row-reverse;
}

.article {
  justify-content: left;
  margin-bottom: 4rem;
  display: flex;
}

@media (min-width: 1600px) {
  .article {
    margin: 0 10%;
  }
}

@media (max-width: 768px) {
  .article {
    display: block;
    margin-bottom: 2rem;
    
  }
}

.article img {
  height: 8rem;
  max-height: 30vh;
  margin-right: 2rem;
}

.about {
  color: white;
}

.about p {
  max-width: 80vw;
}

.contact {
  color: white;
}

.contact p {
  max-width: 80vw;
  text-align: center;
}

.contact p ul li{
  text-align: initial;
}