:root {
  --secondary: #E08090;
  --bg-primary: #161629;
}

body {
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

a {
  color: var(--secondary)
}

a:visited {
  color: var(--secondary)
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-primary);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 30px;
}